





























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsEmailTemplateCard',
    components: {
        VsImage,
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({
        default: null,
        required: false,
    }) private value!: string[]

    @Prop({
        default: null,
        required: true,
    }) private template!: {
        id: number
        user_id: number
        title: string
        description: string
        thumbnail_url: string
        project_id: string
        entity_labels: {
            id: number
            name: string
        }[]
    }

    get user () {
        return UserModule.user
    }

    get previewImage () {
        return this.template.thumbnail_url ? this.template.thumbnail_url + `?t=${Date.now()}` : ''
    }

    get isManagerRoute () {
        return this.$route.name === 'managerEmailEditorIndex'
    }

    get canExportHtml () {
        return this.user?.configuration?.rules.exportHtml
    }
}
