
























































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCreateTagModal from '@/components/VsCreateTagModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { assignEntityLabelToEntities } from '@/api/consoleApi/entityLabels'

@Component({
    name: 'VsAssignTemplateLabelTagModal',
    components: {
        VsCreateTagModal,
    },
})
export default class extends Vue {
    private templateIds: number[] = []

    @Prop({
        default: () => [],
        required: false,
    }) labelTags!: {
        id: any
        name: string
    }[]

    private show = false
    private loading = false
    private selectedTag = ''
    $refs: any

    private async assignTag () {
        this.loading = true
        const data = {
            ids: this.templateIds,
        }
        try {
            await assignEntityLabelToEntities(this.selectedTag, 'templates', data)
            this.$root.$vsToast({
                heading: this.$t('campaigns.index.addTagSuccess'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('tag-assigned')
            this.show = false
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    public openModal (templateIds: number[]) {
        this.templateIds = templateIds
        this.selectedTag = ''
        this.show = true
    }
}
