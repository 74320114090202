var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{attrs:{"maxWidth":"800px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Assegna il template ai tuoi utenti ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(_vm.$t('campaigns.assignTagModal.close'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" "),(_vm.assignedUsers.length > 0)?_c('span',[_vm._v("("+_vm._s(_vm._f("formatNumber")(_vm.assignedUsers.length))+")")]):_vm._e()])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"vs-flex vs-mb-8 vs-gap-4 vs-items-center"},[_c('div',{staticClass:"vs-flex-auto vs-flex vs-items-center vs-gap-4"},[_c('VsFilterContainer',{attrs:{"countFilters":_vm.countFilters,"totalFound":_vm.$tc('general.found', _vm.total)},on:{"clear":function($event){_vm.filters = {
                    search: null,
                    status: null,
                    relationType: null,
                }}}},[_c('VsInput',{attrs:{"placeholder":"Cerca...","size":"small","clearable":"","labelHidden":"","iconName":"search","debounce":"500"},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('VsSelect',{attrs:{"labelHidden":"","size":"small","clearable":"","placeholder":"Status","options":_vm.userStatusOptions},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}),_c('VsSelect',{attrs:{"labelHidden":"","size":"small","clearable":"","placeholder":"Piano","options":_vm.relationTypeOptions},model:{value:(_vm.filters.relationType),callback:function ($$v) {_vm.$set(_vm.filters, "relationType", $$v)},expression:"filters.relationType"}})],1),(_vm.countFilters > 0)?_c('VsButton',{staticClass:"md:vs-hidden",attrs:{"variant":"link"},on:{"click":function($event){_vm.filters = {
                    search: null,
                    status: null,
                    relationType: null,
                }}}},[_vm._v(" Svuota filtri ")]):_vm._e(),_c('div',{staticClass:"vs-body-small vs-flex-none"},[_vm._v(_vm._s(_vm.$tc('general.found', _vm.total)))])],1),_c('div',{staticClass:"vs-flex-none"},[_c('VsSelect',{staticStyle:{"max-width":"120px"},attrs:{"labelHidden":"","size":"small","clearable":"","placeholder":"Ordina per","options":_vm.orderByOptions},model:{value:(_vm.pagination.sortBy),callback:function ($$v) {_vm.$set(_vm.pagination, "sortBy", $$v)},expression:"pagination.sortBy"}})],1)]),_c('div',{staticClass:"vs-relative",staticStyle:{"min-height":"300px"}},[(!_vm.loading && _vm.users.length === 0)?_c('VsNotification',{attrs:{"heading":"Nessun Account trovato"}}):_vm._e(),_vm._l((_vm.users),function(user){return _c('VsActionCard',{key:user._id,staticClass:"vs-mb-4",attrs:{"padding":"small","aspect":_vm.isChecked(user) ? 'primary-100' : 'white'},on:{"click":function($event){return _vm.toggleSelection(user)}}},[_c('div',{staticClass:"vs-flex vs-gap-4 vs-items-center"},[_c('VsCheckbox',{staticClass:"vs-pointer-events-none",attrs:{"labelHidden":"","value":_vm.isChecked(user),"input-value":true}}),_c('div',{staticClass:"vs-body-medium vs-text-grey-900 vs-overflow-hidden",attrs:{"title":user.email}},[_c('div',{staticClass:"vs-truncate vs-h200"},[_vm._v(" "+_vm._s(user.email)+" ")]),_c('div',{staticClass:"vs-body-small vs-truncate"},[_vm._v(" "+_vm._s(((user.firstname) + " " + (user.lastname)))+" "+_vm._s(user.company ? ("(" + (user.company) + ")") : '')+" ")])])],1)])}),(_vm.loading)?_c('VsLoader',{attrs:{"overlay":""}}):_vm._e()],2),_c('VsPagination',{staticClass:"vs-mt-8",attrs:{"totalItems":_vm.total,"filterable":"","expand":"","currentPage":_vm.pagination.page,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }