











































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsEmailTemplateCard from '@/modules/emailEditor/components/VsEmailTemplateCard/Index.vue'
import VsDragoTemplateModal from '@/modules/campaigns/components/VsDragoTemplateModal/Index.vue'
import VsTemplatesTopMultiActions from '@/modules/emailEditor/components/VsTemplatesTopMultiActions/Index.vue'
import {
    getEntityLabels,
    removeEntityLabelToEntity,
} from '@/api/consoleApi/entityLabels'
import { union } from 'lodash'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import VsAssignTemplateLabelTagModal from '@/modules/emailEditor/components/VsAssignTemplateLabelTagModal/Index.vue'
import VsTemplateManageUsersModal from '@/modules/manager/components/VsTemplateManageUsersModal/Index.vue'
import {
    deleteThemeById,
    deleteThemes,
    getTemplates,
    duplicateTemplate,
    exportAsHtml,
} from '@/api/consoleApi/contents/templates'

@Component({
    name: 'EmailEditorIndex',
    components: {
        VsSectionHeader,
        VsLoader,
        VsContainer,
        VsEmailTemplateCard,
        VsDragoTemplateModal,
        VsTemplatesTopMultiActions,
        VsPreviewModal,
        VsConfirm,
        VsAssignTemplateLabelTagModal,
        VsTemplateManageUsersModal,
    },
})
export default class extends Vue {
    private loading = false
    private firstLoader = false
    private templates: any[] = []
    private checkedTemplates: string[] = []
    private total = 0
    private emptyState = false
    private pagination = {
        page: 1,
        itemsPerPage: 10,
        orderBy: 'id|desc',
    }

    private filters: {
        search: string
        entityLabels: any[]
    } = {
        search: '',
        entityLabels: [],
    }

    $refs!: any

    private entityLabels = []

    get isManagerRoute () {
        return this.$route.name === 'managerEmailEditorIndex'
    }

    get entityLabelsOptions () {
        return this.entityLabels.map((el: any) => {
            return {
                label: el.name,
                value: el.id,
            }
        })
    }

    async beforeMount () {
        this.firstLoader = true
        this.getEntityLabels()
        await this.checkEmpty()
        this.firstLoader = false
    }

    @Watch('filters', { immediate: false, deep: true })
    private setCurrentPageAndGetTemplates () {
        if (this.pagination.page !== 1) {
            this.pagination.page = 1
        } else {
            this.getTemplates()
        }
    }

    private async checkEmpty () {
        try {
            const resp = await getTemplates({
                page: 1,
                limit: 1,
            })
            this.emptyState = resp.data.meta.pagination.total === 0
        } catch (e) {
            console.log(e)
        }
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getTemplates () {
        this.loading = true
        try {
            const resp = await getTemplates(
                this.buildParams(),
            )
            this.templates = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.total = 0
            this.templates = []
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            with: 'entityLabels',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.entityLabels.length > 0 ? `entityLabels.id:${this.filters.entityLabels}` : '',
            this.filters.search.trim() !== '' ? `title:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.entityLabels.length > 0 ? 'label_tags.id:in' : '',
            this.filters.search.trim() !== '' ? 'title:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    openCreateModelModal () {
        this.$refs.vsDragoTemplateModal.openModal()
    }

    async getThemeAndOpenDrago (createdTheme: any) {
        try {
            this.$router.push({
                name: this.isManagerRoute ? 'managerTemplateEdit' : 'templateEdit',
                params: {
                    templateId: createdTheme.id,
                },
                query: {
                    openDrago: 'true',
                },
            })
        } catch (e) {
            console.log(e)
        }
    }

    // TODO: devo fare un loop
    private async getEntityLabels () {
        try {
            const resp = await getEntityLabels({
                limit: 1000,
            })
            this.entityLabels = resp.data.data
        } catch (e) {
        }
    }

    private selectAll () {
        this.checkedTemplates = union(
            this.checkedTemplates,
            this.templates.map((el: any) => el.id),
        )
    }

    async deleteThemeById (templateId: number) {
        try {
            await this.$refs.deleteTemplateConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteThemeById(templateId)

            this.$root.$vsToast({
                heading: 'Modello eliminato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.checkedTemplates = []
            await this.getTemplates()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async deleteThemeByIds (templateIds: number[]) {
        try {
            await this.$refs.deleteTemplateConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            const ids = {
                ids: templateIds,
            }
            await deleteThemes(ids)

            this.$root.$vsToast({
                heading: 'Modelli eliminati con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.checkedTemplates = []
            await this.getTemplates()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async duplicateTheme (template: any) {
        this.loading = true
        try {
            const data = {
                name: 'Copia di ' + template.title,
            }
            await duplicateTemplate(template.id, data)

            this.$root.$vsToast({
                heading: 'Modello duplicato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getTemplates()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la duplicazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async exportAsHtml (templateId: string) {
        this.loading = true
        try {
            const { data } = await exportAsHtml(templateId)
            const url = window.URL.createObjectURL(new Blob([data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `template-${templateId}.html`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'esportazione del modello',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async removeLabelTag (tagId: string, templateId: string) {
        this.loading = true
        try {
            await removeEntityLabelToEntity(tagId, 'templates', templateId)
            this.$root.$vsToast({
                heading: 'Tag rimosso con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            await this.getTemplates()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la rimozione del tag',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private async openPreview (template: any) {
        this.$refs.previewModal.openModalNew({
            ...template,
        }, 'emailTemplate')
    }

    private openTemplateManageUsers (template: any) {
        this.$refs.vsTemplateManageUsersModal.openModal(template)
    }
}
