


















































































































































import { getManagerTemplateAssignedUsers, putManagerTemplateAssignedUsers } from '@/api/consoleApi/manager'
import { getChilds } from '@/api/userapi/manager/users'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import { userStatusEnum } from '@/utils/users'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'

@Component({
    name: 'VsTemplateManageUsersModal',
    components: {
        VsLoader,
        VsFilterContainer,
        VsActionCard,
    },
})
export default class extends Vue {
    private template!: any

    private assignedUsers: string[] = []
    private open = false
    private loading = false
    users: any[] = []
    total = 0
    pagination = {
        page: 1,
        itemsPerPage: 10,
        sortBy: null,
    }

    private filters: any = {
        status: null,
        relationType: null,
        search: null,
    }

    get countFilters () {
        let count = 0
        if (this.filters.relationType) count++
        if (this.filters.status) count++
        if (this.filters.search) count++
        return count
    }

    get userStatusOptions () {
        return [
            {
                value: userStatusEnum.ENABLED,
                label: this.$t(`manager.userStatus.${userStatusEnum.ENABLED}`),
            },
            {
                value: userStatusEnum.DISABLED,
                label: this.$t(`manager.userStatus.${userStatusEnum.DISABLED}`),
            },
        ]
    }

    get relationTypeOptions () {
        return [
            {
                value: 'starter',
                label: 'Starter',
            },
            {
                value: 'expert',
                label: 'Expert',
            },
            // {
            //     value: 'indipendent',
            //     label: 'Indipendent',
            // },
        ]
    }

    get orderByOptions () {
        return [
            {
                value: '-|createdAt',
                label: 'Più recenti',
            },
            {
                value: '+|createdAt',
                label: 'Meno recenti',
            },
            {
                value: '-|updatedAt',
                label: 'Ultime modifiche',
            },
            {
                value: '-|lastLogin',
                label: 'Ultimo accesso',
            },
        ]
    }

    openModal (template: any) {
        this.template = template
        this.assignedUsers = []
        this.getManagerTemplateAssignedUsers()
        this.getUsers()
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    async getManagerTemplateAssignedUsers () {
        this.loading = true
        try {
            const resp = await getManagerTemplateAssignedUsers(this.template.id)
            this.assignedUsers = resp.data.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    @Watch('filters', { immediate: false, deep: true })
    async setPageAndGetUsers () {
        if (this.pagination.page > 1) {
            this.pagination.page = 1
        } else {
            this.getUsers()
        }
    }

    @Watch('pagination', { immediate: false, deep: true })
    async getUsers () {
        this.loading = true
        try {
            const resp = await getChilds(this.generateQuery())
            this.users = resp.data.docs
            this.total = resp.data.count
        } catch (e) {
            console.log(e)
            this.total = 0
            this.users = []
        }
        this.loading = false
    }

    private generateQuery () {
        const sortBy = this.pagination.sortBy || '-|createdAt'
        const sortSplit = sortBy.split('|')
        const query: any = {
            limit: this.pagination.itemsPerPage,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            sort: `${sortSplit[0]}${sortSplit[1]}`,
        }

        const filter: any = {
            $and: [],
        }

        if (this.filters.search) {
            const checkForHexRegExp = /^[a-f\d]{24}$/i
            const $or: any[] = [
                { email: { $regex: this.filters.search, $options: 'i' } },
                { firstname: { $regex: this.filters.search, $options: 'i' } },
                { lastname: { $regex: this.filters.search, $options: 'i' } },
                { company: { $regex: this.filters.search, $options: 'i' } },
            ]
            if (checkForHexRegExp.test(this.filters.search)) {
                $or.push({ _id: this.filters.search })
            }
            filter.$and.push({
                $or,
            })
        }

        if (this.filters.status) {
            filter.$and.push({
                status: this.filters.status,
            })
        }

        if (this.filters.relationType) {
            filter.$and.push({
                'parent.relationType': this.filters.relationType === 'indipendent' ? '' : this.filters.relationType,
            })
        }

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }

    isChecked (user: any) {
        return this.assignedUsers.includes(user._id)
    }

    toggleSelection (user: any) {
        const index = this.assignedUsers.findIndex(el => el === user._id)
        if (index === -1) {
            this.assignedUsers.push(user._id)
        } else {
            this.assignedUsers.splice(index, 1)
        }
    }

    async save () {
        this.loading = true
        try {
            await putManagerTemplateAssignedUsers(this.template.id, { ids: this.assignedUsers })
            this.$root.$vsToast({
                heading: 'Utenti assegnati con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
            const errCode = get(e, 'response.status', false)
            let message = ''
            if (errCode === 404) {
                message = 'template non trovato'
            }
            if (errCode === 400) {
                message = `Utente non trovato`
                this.getManagerTemplateAssignedUsers()
                this.getUsers()
            }
            this.$root.$vsToast({
                heading: 'Errore durante l\'assegnazione degli utenti',
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}

