var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsListingCard',{attrs:{"aspect":(this.value && this.value.indexOf(_vm.template.id) > -1) ? 'primary-100' : 'white'},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('VsImage',{staticClass:"vs-rounded-small",staticStyle:{"width":"126px"},attrs:{"src":_vm.previewImage,"enableHover":true,"backgroundPosition":"center"}},[_c('VsButton',{attrs:{"variant":"icon","aspect":"ghost","iconName":"zoom-in","size":"large"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('preview')}}})],1)]},proxy:true},{key:"accessories",fn:function(){return [_c('VsCheckbox',{staticClass:"vs-inline-flex vs-align-top vs-mr-2 vs-mb-2",attrs:{"size":"large","value":_vm.value,"inputValue":_vm.template.id,"labelHidden":true},on:{"input":function($event){return _vm.$emit('input', $event)}}}),_vm._l((_vm.template.entity_labels),function(entity_label){return _c('VsBadge',{key:entity_label.id,staticClass:"vs-mr-2 vs-mb-2 vs-align-top",attrs:{"closable":"","closeTip":"Rimovi tag"},on:{"click":function($event){return _vm.$emit('remove-label-tag', entity_label.id)}}},[_vm._v(" "+_vm._s(entity_label.name)+" ")])})]},proxy:true},{key:"heading",fn:function(){return [_c('router-link',{staticClass:"vs-no-underline vs-h400 vs-align-middle vs-cursor-pointer vs-text-grey-900 vs-truncate",attrs:{"to":{
                name: _vm.isManagerRoute ? 'managerTemplateEdit' : 'templateEdit',
                params: {
                    templateId: _vm.template.id,
                },
            },"title":_vm.template.title}},[_vm._v(" "+_vm._s(_vm.template.title)+" ")])]},proxy:true},{key:"default",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.template.description)+" ")])]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"vs-flex vs-gap-2"},[_c('VsButton',{staticClass:"vs-hidden md:vs-inline-flex vs-ml-2",attrs:{"aspect":"secondary","to":{
                    name: _vm.isManagerRoute ? 'managerTemplateEdit' : 'templateEdit',
                    params: {
                        templateId: _vm.template.id,
                    },
                }}},[_vm._v(" Modifica ")]),_c('VsDropdownButton',{attrs:{"size":"medium"}},[_c('VsActionItem',{staticClass:"md:vs-hidden",attrs:{"label":'Modifica',"to":{
                        name: _vm.isManagerRoute ? 'managerTemplateEdit' : 'templateEdit',
                        params: {
                            templateId: _vm.template.id,
                        },
                    }}}),_c('VsActionItem',{attrs:{"label":"Anteprima"},on:{"click":function($event){return _vm.$emit('preview')}}}),(_vm.isManagerRoute)?_c('VsActionItem',{attrs:{"label":"Gestisci assegnazione utenti"},on:{"click":function($event){return _vm.$emit('manage-users')}}}):_vm._e(),_c('VsActionItem',{attrs:{"label":"Duplica"},on:{"click":function($event){return _vm.$emit('duplicate')}}}),(!_vm.canExportHtml)?_c('VsActionItem',{attrs:{"label":"Esporta html","appendIconName":"lock","disabled":""},on:{"click":function($event){return _vm.$root.$vsOpenBlockedModal()}}}):_vm._e(),(_vm.canExportHtml)?_c('VsActionItem',{attrs:{"label":"Esporta html"},on:{"click":function($event){return _vm.$emit('export-html')}}}):_vm._e(),_c('VsActionItem',{attrs:{"aspect":"alert","label":"Elimina"},on:{"click":function($event){return _vm.$emit('delete')}}})],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }